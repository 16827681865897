const pageTitles = {
  sv: {
    'var-e-handel': 'Vår e-handel',
    'frakt-och-leverans': 'Frakt och leverans',
    'retur-och-reklamation': 'Retur och Reklamation',
    smakgaranti: 'Smakgaranti',
    kopvillkor: 'Köpvillkor',
    'kontakta-oss': 'Kontakta oss',
    'cookie-policy': 'Cookie policy',
    'integritets-policy': 'Integritetspolicy',
    'bonuscard-och-uppfodarrabatt': 'Bonuscard och uppfödarrabatt',
  },
  uk: {
    'about-us': 'About us',
    'shipping-and-delivery': 'Shipping and delivery',
    'returns-and-refunds': 'Returns and refunds',
    'terms-of-purchase': 'Terms of purchase',
    'contact-us': 'Contact us',
    'cookie-policy': 'Cookie policy',
    'privacy-policy': 'Privacy policy',
  },
} as const;

const pageSlugTranslations = {
  'about-us': {
    uk: 'about-us',
    sv: 'var-e-handel',
  },
  'shipping-and-delivery': {
    uk: 'shipping-and-delivery',
    sv: 'frakt-och-leverans',
  },
  'returns-and-refunds': {
    uk: 'returns-and-refunds',
    sv: 'retur-och-reklamation',
  },
  'terms-of-purchase': {
    uk: 'terms-of-purchase',
    sv: 'kopvillkor',
  },
  'contact-us': {
    uk: 'contact-us',
    sv: 'kontakta-oss',
  },
  'cookie-policy': {
    uk: 'cookie-policy',
    sv: 'cookie-policy',
  },
  'privacy-policy': {
    uk: 'privacy-policy',
    sv: 'integritets-policy',
  },
} as const;

const getInfoPageByKey = (
  key: keyof typeof pageSlugTranslations,
  locale: keyof typeof pageTitles
) => {
  let slug;
  let title;
  // when we have typescript 5.3 we could use a switch(true) instead https://devblogs.microsoft.com/typescript/announcing-typescript-5-3-rc/#switch-true-narrowing
  if (locale === 'uk') {
    slug = pageSlugTranslations[key].uk;
    title = pageTitles.uk[slug];
  } else {
    slug = pageSlugTranslations[key].sv;
    title = pageTitles[locale][slug];
  }
  return { key, slug, title };
};

const pages = (locale: keyof typeof pageTitles) =>
  Object.keys(pageTitles[locale]).map((slug) => ({
    slug,
    // @ts-ignore
    title: pageTitles[locale][slug],
    metaDesc: slug === 'cookie-policy' ? pageTitles[locale][slug] : '',
  }));

const getPageContent = async (
  slug: string,
  locale: keyof typeof pageTitles
) => {
  const runtimeConfig = useRuntimeConfig();

  const { clientUrl } = runtimeConfig.public;
  const pageContent =
    slug === 'cookie-policy'
      ? ''
      : await $fetch<string>(
          `${clientUrl}/ecom-assets/content/info/${locale}/${slug}.html`
        );

  return {
    slug,
    // @ts-ignore
    title: pageTitles[locale][slug],
    content: pageContent,
    metaDesc: slug === 'cookie-policy' ? pageTitles[locale][slug] : '',
  };
};

const getDefaultLocalizedPage = (to: any) => {
  const { $i18n } = useNuxtApp();
  const locale = $i18n.locale.value as keyof typeof pageTitles;

  const countryConfig = useShopCountryConfig();
  const language = useLanguage();

  const { slug } = to.params;

  const redirectPage = `/${countryConfig.rootPath[language]}/info/${
    Object.keys(pageTitles[locale])[0]
  }`;

  const from = (pageTitles[locale] as any)[slug as string];

  if (from === undefined) {
    return navigateTo(redirectPage, {
      redirectCode: 301,
    });
  }
  return true;
};

export {
  pageTitles,
  pageSlugTranslations,
  pages,
  getInfoPageByKey,
  getPageContent,
  getDefaultLocalizedPage,
};
