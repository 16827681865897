import { SUBSCRIPTION_DISCOUNT_PERCENTAGE } from '../config/subscriptions-config';

const calculateSubscriptionDiscountPrice = (price: number): number => {
  if (!SUBSCRIPTION_DISCOUNT_PERCENTAGE) {
    return price;
  }

  return price * (1 - SUBSCRIPTION_DISCOUNT_PERCENTAGE / 100);
};

export { calculateSubscriptionDiscountPrice };
