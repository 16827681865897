import revive_payload_client_Frd8gASYWa from "/home/node/client/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_3Bi0E2Ktsf from "/home/node/client/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_CrWB4n4PyO from "/home/node/client/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_T5aD6w6QZh from "/home/node/client/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_aQoh9E5uaZ from "/home/node/client/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/node/client/.firstvet/.nuxt/components.plugin.mjs";
import prefetch_client_5ZRYOjuei0 from "/home/node/client/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_CHalsvJBex from "/home/node/client/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import plugin_PIdCykQ0f0 from "/home/node/client/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_client_QZgtPhXvdA from "/home/node/client/node_modules/nuxt-viewport/dist/runtime/plugin.client.mjs";
import composition_BfDtacIhbF from "/home/node/client/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_huatSqtcYP from "/home/node/client/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_kCUITUWXUN from "/home/node/client/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_yxgjJ9XxnV from "/home/node/client/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_aMPjkA2BVh from "/home/node/client/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import _02_vue_toast_notification_client_yPZUcNoBCn from "/home/node/client/plugins/02.vue-toast-notification.client.ts";
import _03_i18n_eXZvkYvpcK from "/home/node/client/plugins/03.i18n.js";
import api_fetch_Xi8ZzuhpzE from "/home/node/client/plugins/api-fetch.ts";
import apollo_Z8yB0BbdVf from "/home/node/client/plugins/apollo.ts";
import detect_back_navigation_client_BvMM8wcck9 from "/home/node/client/plugins/detect-back-navigation.client.ts";
import directives_5G44YSCrCb from "/home/node/client/plugins/directives.js";
import ecom2_loader_client_IyEf0HNzhz from "/home/node/client/plugins/ecom2-loader.client.ts";
import error_handler_uo4Xy6fTyv from "/home/node/client/plugins/error-handler.ts";
import filters_UAx321YA7B from "/home/node/client/plugins/filters.ts";
import lite_youtube_embed_client_E3sq0wlHar from "/home/node/client/plugins/lite-youtube-embed.client.ts";
import original_location_client_TeumiwBM7L from "/home/node/client/plugins/original-location.client.ts";
import sentry_client_eyJkqU3H9Q from "/home/node/client/plugins/sentry.client.ts";
import triggerbee_client_XGlKCuPCQA from "/home/node/client/plugins/triggerbee.client.js";
import vee_validate_RifC5ontxl from "/home/node/client/plugins/vee-validate.ts";
import viewport_client_QHSZ5HDZGG from "/home/node/client/plugins/viewport.client.ts";
export default [
  revive_payload_client_Frd8gASYWa,
  unhead_3Bi0E2Ktsf,
  router_CrWB4n4PyO,
  payload_client_T5aD6w6QZh,
  plugin_vue3_aQoh9E5uaZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5ZRYOjuei0,
  plugin_CHalsvJBex,
  plugin_PIdCykQ0f0,
  plugin_client_QZgtPhXvdA,
  composition_BfDtacIhbF,
  i18n_huatSqtcYP,
  chunk_reload_client_kCUITUWXUN,
  check_outdated_build_client_yxgjJ9XxnV,
  plugin_aMPjkA2BVh,
  _02_vue_toast_notification_client_yPZUcNoBCn,
  _03_i18n_eXZvkYvpcK,
  api_fetch_Xi8ZzuhpzE,
  apollo_Z8yB0BbdVf,
  detect_back_navigation_client_BvMM8wcck9,
  directives_5G44YSCrCb,
  ecom2_loader_client_IyEf0HNzhz,
  error_handler_uo4Xy6fTyv,
  filters_UAx321YA7B,
  lite_youtube_embed_client_E3sq0wlHar,
  original_location_client_TeumiwBM7L,
  sentry_client_eyJkqU3H9Q,
  triggerbee_client_XGlKCuPCQA,
  vee_validate_RifC5ontxl,
  viewport_client_QHSZ5HDZGG
]