export interface BaseCountryConfig {
  hasShop: boolean;
  country: string;
  cmsSiteHandle: string;
  countryCode: string;
  ISOCountryCode: string;
  region: string | null;
  callingCode: number;
  i18n: {
    name: string;
    code: string; // What we use in the url
    iso: string;
    file: string;
    dir: string;
    lang: string;
    regionCode: string; // What we use as key for getting the country config for the locale
  }[];
  rootPath: { [language: string]: string };
  routes: {
    [language: string]: {
      shop?: string;
      product?: string;
      brands?: string;
      info?: string;
      campaign?: string;
      recommendedProducts?: string | null;
      dashboard?: string | null;
      dashboardSubscriptions?: string | null;
      receipt?: string | null;
      checkout?: string | null;
      myPrescriptions?: string | null;
      search?: string | null;
      questions?: string | null;
    };
  };
}

export interface ShopCountryConfig extends BaseCountryConfig {
  countryId: number;
  currency: {
    code: string;
    symbol: string;
    position: string;
  };
  decimalPricing: boolean;
  paymentProvider: string;
  partners: {
    alt: string;
    logo: string;
    url: string;
  }[];
  checkoutTrackingSteps: { [step: string]: number };
  taxRate: number;
  shippingRates: {
    rate: number;
    freeFromAmount: number;
  };
  deliveryTimes: {
    minDays: number;
    maxDays: number;
  };
  homePageLinks: {
    imgSrc: string;
    alt: string;
    title: string;
    path: string;
    external?: boolean;
  }[];
  frozenFood: {
    handlingFee: number;
    isEnabled: boolean;
    reducedHandlingFee: number;
    reducedFeeBrandsIds: ReadonlyArray<number>;
  } | null;
  shopOpened: number;
  warehouseAddress?: {
    street: string;
    postcode: string;
    city: string;
    countrycode: string;
    contactName: string;
    email: string;
    phone: string;
  } | null;
  categories: {
    [language: string]: {
      facets: string;
      animalType: string;
      areas: string;
      featuredProducts: string;
      christmas: string;
      recommendedByVets: string;
      subscriptions: string;
    };
  };
  facets: {
    [language: string]: {
      brand: string;
      animalType: string;
      campaign: string;
    };
  };
  defaultCommonSearches: {
    [language: string]: string[];
  };
  legalLinks: {
    [language: string]: {
      accessibility?: string;
      cookies: string;
      privacy: string;
      terms: string;
    };
  };
  breadcrumbHomePage: {
    [language: string]: {
      entityId: number;
      path: string;
      name: string;
      longName: string;
    };
  };
}

export const baseCountryConfigs: {
  [brand: string]: {
    [regionCode: string]: BaseCountryConfig;
  };
} = {
  firstvet: {
    se: {
      hasShop: true,
      cmsSiteHandle: 'swedish',
      country: 'Sweden',
      countryCode: 'se',
      ISOCountryCode: 'SE',
      region: null,
      callingCode: 46,
      i18n: [
        {
          name: 'Sweden',
          code: 'sv',
          iso: 'sv-SE',
          file: 'sv-SE.ts',
          dir: 'ltr',
          lang: 'sv',
          regionCode: 'se',
        },
      ],
      rootPath: {
        sv: 'sv/butik',
      },
      routes: {
        sv: {
          shop: 'butik',
          product: 'produkt',
          brands: 'varumarken',
          info: 'info',
          campaign: 'kampanjer',
          recommendedProducts: 'rekommenderade-produkter',
          dashboard: 'mina-sidor',
          dashboardSubscriptions: 'mina-sidor/prenumerationer',
          receipt: 'kvitto',
          checkout: 'kassa',
          myPrescriptions: 'mina-sidor/recept',
          search: 'sok',
          questions: 'fragor',
        },
      },
    },
    uk: {
      hasShop: false,
      cmsSiteHandle: 'firstvetUk',
      country: 'United Kingdom',
      countryCode: 'uk',
      ISOCountryCode: 'GB', // TODO: we should use the ISO 3166-1 country code everywhere
      region: null,
      callingCode: 44,
      i18n: [
        {
          name: 'United Kingdom',
          code: 'uk',
          iso: 'en-GB',
          file: 'en-GB.ts',
          dir: 'ltr',
          lang: 'en',
          regionCode: 'uk',
        },
      ],
      rootPath: {
        en: 'uk',
      },
      routes: {
        en: {
          shop: 'shop',
          product: 'product',
          brands: 'brands',
          info: 'info',
          campaign: 'special-offers',
          recommendedProducts: null, // Not yet implemented
          dashboard: 'dashboard',
          dashboardSubscriptions: 'dashboard/subscriptions',
          receipt: 'receipt',
          checkout: 'checkout',
          myPrescriptions: 'my-prescriptions',
          search: 'search',
          questions: 'questions',
        },
      },
    },
    dk: {
      hasShop: false,
      country: 'Denmark',
      cmsSiteHandle: 'firstvetDk',
      countryCode: 'dk',
      ISOCountryCode: 'DK',
      region: null,
      callingCode: 45,
      i18n: [
        {
          name: 'Denmark',
          code: 'dk',
          iso: 'da-DK',
          file: 'da-DK.ts',
          dir: 'ltr',
          lang: 'da',
          regionCode: 'dk',
        },
      ],
      rootPath: { da: 'dk' },
      routes: {
        da: {
          questions: 'sporgsmal',
        },
      },
    },
    de: {
      hasShop: false,
      country: 'Germany',
      cmsSiteHandle: 'firstvetDe',
      countryCode: 'de',
      ISOCountryCode: 'DE', // TODO: we should use the  ISO 3166-1 country code everywhere
      region: null,
      callingCode: 49,
      i18n: [
        {
          name: 'Germany',
          code: 'de',
          iso: 'de-DE',
          file: 'de-DE.ts',
          dir: 'ltr',
          lang: 'de',
          regionCode: 'de',
        },
      ],
      rootPath: { de: 'de' },
      routes: {
        de: {
          questions: 'fragen',
        },
      },
    },
    us: {
      hasShop: false,
      country: 'United States',
      cmsSiteHandle: 'firstvetUs',
      countryCode: 'us',
      ISOCountryCode: 'US', // TODO: we should use the  ISO 3166-1 country code everywhere
      region: null,
      callingCode: 1,
      i18n: [
        {
          name: 'United States',
          code: 'us',
          iso: 'en-US',
          file: 'en-US.ts',
          dir: 'ltr',
          lang: 'en',
          regionCode: 'us',
        },
      ],
      rootPath: { en: 'us' },
      routes: {
        en: {
          questions: 'questions',
        },
      },
    },
    fi: {
      hasShop: false,
      country: 'Finland',
      cmsSiteHandle: 'firstvetFi',
      countryCode: 'fi',
      ISOCountryCode: 'FI', // TODO: we should use the  ISO 3166-1 country code everywhere
      region: null,
      callingCode: 358,
      i18n: [
        {
          name: 'Finland',
          code: 'fi',
          iso: 'fi-FI',
          file: 'fi-FI.ts',
          dir: 'ltr',
          lang: 'fi',
          regionCode: 'fi',
        },
      ],
      rootPath: {
        fi: 'fi',
      },
      routes: {
        fi: {
          questions: 'kysymykset',
        },
      },
    },
    no: {
      hasShop: false,
      country: 'Norway',
      cmsSiteHandle: 'firstvetNorway',
      countryCode: 'no',
      ISOCountryCode: 'NO', // TODO: we should use the  ISO 3166-1 country code everywhere
      region: null,
      callingCode: 47,
      i18n: [
        {
          name: 'Norway',
          code: 'no',
          iso: 'nb-NO',
          file: 'nb-NO.ts',
          dir: 'ltr',
          lang: 'nb',
          regionCode: 'no',
        },
      ],
      rootPath: {
        nb: 'no',
      },
      routes: {
        nb: {
          questions: 'sporsmal',
        },
      },
    },
  },
  telus: {
    'ca-bc': {
      hasShop: true,
      country: 'Canada',
      countryCode: 'ca',
      ISOCountryCode: 'CA',
      region: 'British Columbia',
      callingCode: 1,
      i18n: [
        {
          name: 'Canada (en)',
          code: 'en',
          iso: 'en-CA',
          file: 'en-CA.ts',
          dir: 'ltr',
          lang: 'en',
          regionCode: 'ca-bc',
        },
      ],
      cmsSiteHandle: 'swedish', // FIXME:
      rootPath: { en: 'en/shop' },
      routes: {
        en: {
          shop: 'shop',
          product: 'product',
          brands: 'brands',
          info: 'info',
          campaign: 'special-offers',
          recommendedProducts: null,
          dashboard: null,
          receipt: 'receipt',
          checkout: 'checkout',
          myPrescriptions: null,
          search: 'search',
          questions: null,
        },
      },
    },
  },
};

export const shopCountryConfigs: {
  [brand: string]: {
    [regionCode: string]: ShopCountryConfig;
  };
} = {
  firstvet: {
    se: {
      ...baseCountryConfigs.firstvet.se,
      countryId: 752,
      currency: {
        code: 'SEK',
        symbol: 'kr',
        position: 'after',
      },
      decimalPricing: false,
      paymentProvider: 'klarna',
      partners: [
        {
          alt: 'Läkemedelsverket',
          logo: 'apotek.svg',
          url: 'https://www.lakemedelsverket.se/sv/handel-med-lakemedel/apotek',
        },
        {
          alt: 'Klarna',
          logo: 'klarna.svg',
          url: 'https://www.klarna.com/',
        },
        {
          alt: 'VISA',
          logo: 'visa.svg',
          url: 'https://www.visa.com/',
        },
        {
          alt: 'MasterCard',
          logo: 'mastercard.svg',
          url: 'https://www.mastercard.com/',
        },
        {
          alt: 'Postnord',
          logo: 'postnord.svg',
          url: 'https://www.postnord.se/',
        },
        {
          alt: 'Budbee',
          logo: 'budbee.svg',
          url: 'https://budbee.com/',
        },
        {
          alt: 'BankID',
          logo: 'bankid.svg',
          url: 'https://www.bankid.com/',
        },
      ],
      checkoutTrackingSteps: {
        REVIEW_CART: 1,
        POSTAL_CODE: 2,
        SHIPPING: 3,
        PAYMENT_INFO: 4,
        RECEIPT: 5,
      },
      taxRate: 0.25,
      shippingRates: {
        rate: 59, // NOTE: this is default value and might be overridden by shipping-config-sv.ts
        freeFromAmount: 599,
      },
      deliveryTimes: {
        minDays: 1,
        maxDays: 3,
      },
      homePageLinks: [
        {
          imgSrc: 'sections/puff-pharmacy.webp',
          alt: 'Apotek',
          title: 'Apotek',
          path: '/sv/djurapotek-hamta-ut-lakemedel-till-husdjur',
        },
        {
          imgSrc: 'sections/puff-dog.webp',
          alt: 'Hundprodukter',
          title: 'Hundprodukter',
          path: '/sv/butik/djurtyp/hund',
        },
        {
          imgSrc: 'sections/puff-cat.webp',
          alt: 'Kattprodukter',
          title: 'Kattprodukter',
          path: '/sv/butik/djurtyp/katt',
        },
        {
          imgSrc: 'sections/230x203_campaigns.webp',
          alt: 'Se alla våra kampanjer',
          title: 'Se alla våra kampanjer',
          path: '/sv/butik/kampanjer',
        },
      ],
      frozenFood: {
        handlingFee: 49,
        isEnabled: true,
        reducedHandlingFee: 29,
        reducedFeeBrandsIds: [
          324, // Hugo & Celine in prod
          // 59 , // Monster in staging
        ],
      },
      shopOpened: 2020,
      warehouseAddress: {
        street: 'Uppfinnarvägen 4 c/o Veddesta Distribution AB',
        postcode: '19561',
        city: 'Arlandastad',
        countrycode: 'SE',
        contactName: 'Veddesta Distribution AB',
        email: 'order@veddesta-distribution.se',
        phone: '+46859087530',
      },
      categories: {
        sv: {
          facets: 'facets',
          animalType: 'djurtyp',
          areas: 'omraden',
          featuredProducts: 'utvalda-produkter',
          christmas: 'julprodukter',
          recommendedByVets: 'veterinaren-rekommenderar',
          subscriptions: 'prenumerationer',
        },
      },
      facets: {
        sv: {
          brand: 'Varumärke',
          animalType: 'Djurtyp',
          campaign: 'Kampanj',
        },
      },
      defaultCommonSearches: {
        sv: ['Våtfoder', 'Tuggben', 'Kattsand', 'Hundgodis'],
      },
      legalLinks: {
        sv: {
          cookies: 'https://firstvet.com/sv/cookies',
          privacy: 'https://firstvet.com/sv/integritetspolicy',
          terms: 'https://firstvet.com/sv/anvandarvillkor',
        },
      },
      breadcrumbHomePage: {
        sv: {
          entityId: 0,
          path: '/sv/butik',
          name: 'Hem',
          longName: 'Hem',
        },
      },
    },
    uk: {
      ...baseCountryConfigs.firstvet.uk,
      countryId: 826,
      currency: {
        code: 'GBP',
        symbol: '£',
        position: 'before',
      },
      decimalPricing: true,
      paymentProvider: 'stripe',
      partners: [
        {
          alt: 'Apple Pay',
          logo: 'apple-pay.svg',
          url: 'https://www.apple.com/apple-pay/',
        },
        {
          alt: 'Google Pay',
          logo: 'google-pay.svg',
          url: 'https://pay.google.com/',
        },
        {
          alt: 'PayPal',
          logo: 'paypal.svg',
          url: 'https://www.paypal.com/',
        },
        {
          alt: 'VISA',
          logo: 'visa.svg',
          url: 'https://www.visa.com/',
        },
        {
          alt: 'MasterCard',
          logo: 'mastercard.svg',
          url: 'https://www.mastercard.com/',
        },
        {
          alt: 'American Express',
          logo: 'amex.svg',
          url: 'https://www.americanexpress.com/',
        },
        {
          alt: 'Japan Credit Bureau',
          logo: 'japan-credit-bureau.svg',
          url: 'https://www.global.jcb/',
        },
        {
          alt: 'Diners Club International',
          logo: 'diners.svg',
          url: 'https://www.dinersclub.com/',
        },
        {
          alt: 'Discover',
          logo: 'discover.svg',
          url: 'https://www.discover.com/',
        },
        {
          alt: 'China UnionPay',
          logo: 'china-unionpay.svg',
          url: 'https://www.unionpayintl.com/en/',
        },
      ],
      checkoutTrackingSteps: {
        REVIEW_CART: 1,
        POSTAL_CODE: 2,
        PAYMENT_INFO: 3,
        RECEIPT: 4,
      },
      taxRate: 0.2,
      shippingRates: {
        rate: 3.99,
        freeFromAmount: 49,
      },
      deliveryTimes: {
        minDays: 2,
        maxDays: 5,
      },
      homePageLinks: [
        {
          imgSrc: 'sections/230x203_dog_food.webp',
          alt: 'Veterinary Diet Dog Food',
          title: 'Veterinary Diet Dog Food',
          path: '/uk/shop/animal-type/dog/dog-food/veterinary-diet-food',
        },
        {
          imgSrc: 'sections/230x203_dog_supplements.webp',
          alt: 'Dog Supplements',
          title: 'Dog Supplements',
          path: '/uk/shop/animal-type/dog/supplements',
        },
        {
          imgSrc: 'sections/230x203_cat.webp',
          alt: 'Cat Food',
          title: 'Cat Food',
          path: '/uk/shop/animal-type/cat/cat-food',
        },
        {
          imgSrc: 'sections/230x203_campaigns.webp',
          alt: 'Special Offers',
          title: 'Special Offers',
          path: '/uk/shop/special-offers',
        },
      ],
      frozenFood: {
        handlingFee: 0,
        isEnabled: false,
        reducedHandlingFee: 0,
        reducedFeeBrandsIds: [],
      },
      shopOpened: 2022,
      categories: {
        en: {
          facets: 'facets',
          animalType: 'animal-type',
          areas: 'health-conditions',
          featuredProducts: 'featured-products',
          christmas: 'christmas-products',
          recommendedByVets: 'vet-recommended',
          subscriptions: 'subscriptions',
        },
      },
      facets: {
        en: {
          brand: 'Brand',
          animalType: 'Animal Type',
          campaign: 'Discount',
        },
      },
      defaultCommonSearches: {
        en: ['Wet food', 'Bones', 'Cat litter', 'Dog treats'],
      },
      legalLinks: {
        en: {
          accessibility: 'https://firstvet.com/uk/accessibility',
          cookies: 'https://firstvet.com/uk/cookies',
          privacy: 'https://firstvet.com/uk/privacy-policy',
          terms: 'https://firstvet.com/uk/terms-of-service',
        },
      },
      breadcrumbHomePage: {
        en: {
          entityId: 0,
          path: '/uk/shop',
          name: 'Home',
          longName: 'Home',
        },
      },
    },
  },
  telus: {
    'ca-bc': {
      ...baseCountryConfigs.telus['ca-bc'],
      countryId: 124,
      currency: {
        code: 'CAD',
        symbol: '$',
        position: 'before',
      },
      decimalPricing: true,
      taxRate: 0.25,
      shippingRates: {
        rate: 3,
        freeFromAmount: 10,
      },
      deliveryTimes: {
        minDays: 1,
        maxDays: 3,
      },
      frozenFood: null,
      paymentProvider: 'stripe',
      partners: [], // Empty for now
      homePageLinks: [], // Empty for now
      checkoutTrackingSteps: {
        REVIEW_CART: 1,
        POSTAL_CODE: 2,
        PAYMENT_INFO: 3,
        RECEIPT: 4,
      },
      categories: {
        en: {
          facets: 'facets',
          animalType: 'animal-type',
          areas: 'health-conditions',
          featuredProducts: 'featured-products',
          christmas: 'christmas-products',
          recommendedByVets: 'vet-recommended',
          subscriptions: 'subscriptions',
        },
      },
      facets: {
        en: {
          brand: 'Brand',
          animalType: 'Animal Type',
          campaign: 'Discount',
        },
      },
      defaultCommonSearches: {
        en: ['Wet food', 'Bones', 'Cat litter', 'Dog treats'],
      },
      legalLinks: {
        en: {
          cookies: '',
          privacy: '',
          terms: '',
        },
      },
      shopOpened: 2024,
      breadcrumbHomePage: {
        en: {
          entityId: 0,
          path: '/en/shop',
          name: 'Home',
          longName: 'Home',
        },
      },
    },
  },
};
