const capitalize = (string: string) =>
  (string?.charAt(0)?.toUpperCase() || '') + (string?.slice(1) || '');

const isCharLetter = (c: string) => c.toLowerCase() !== c.toUpperCase();

const sluggify = (string: string) => {
  if (!string) {
    return string;
  }

  let slug = string.replace(/^\s+|\s+$/g, ''); // trim
  slug = slug.toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = 'àáåäâèéëêìíïîòóöôùúüûñç·/_,:;';
  const to = 'aaaaaeeeeiiiioooouuuunc------';
  for (let i = 0, l = from.length; i < l; i += 1) {
    slug = slug.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  slug = slug
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return slug;
};

const safeJsonParse = (jsonString: string | null) => {
  try {
    return jsonString ? JSON.parse(jsonString) : null;
  } catch (error) {
    console.log(`Unable to parse json string`, jsonString);
    return null;
  }
};

const safeJsonStringify = (jsonObject: any, ...options: any[]) => {
  try {
    return JSON.stringify(jsonObject, ...options);
  } catch (error) {
    console.log(`Unable to stringify json object`, jsonObject);
    return null;
  }
};

const safeRegexExp = (pattern: string, flags?: string) => {
  try {
    return new RegExp(pattern, flags);
  } catch (error) {
    console.log('Unable to create RegExp', pattern);
    return '';
  }
};

const addTrailingSlash = (url: string) => url?.replace(/\/?$/, '/');

const removeLocale = (url: string, locale: string) => {
  if (url.startsWith(`/${locale}/`)) {
    return url.substring(1 + locale.length);
  }
  return url;
};

const removeTrailingSlash = (url: string) => url?.replace(/\/$/, '');

const removeSlashes = (url: string) =>
  url?.replace(/\/$/, '').replace(/^\//, '');

const removeWhitespace = (string: string) => string?.replace(/\s/g, '');

function stripHtml(htmlString: string): string {
  // Remove HTML comments
  let result = htmlString.replace(/<!--[\s\S]*?-->/g, '');

  // Remove script tags and their content
  result = result.replace(
    /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi,
    ''
  );

  // Remove style tags and their content
  result = result.replace(
    /<style\b[^<]*(?:(?!<\/style>)<[^<]*)*<\/style>/gi,
    ''
  );

  // Remove remaining HTML tags
  result = result.replace(/<[^>]+>/g, '');

  // Decode common HTML entities
  result = result
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&ndash;/g, '–')
    .replace(/&quot;/g, '"')
    .replace(/&apos;/g, "'")
    .replace(/&nbsp;/g, ' ')
    .replace(/&aring;/g, 'å')
    .replace(/&Aring;/g, 'Å')
    .replace(/&auml;/g, 'ä')
    .replace(/&Auml;/g, 'Ä')
    .replace(/&ouml;/g, 'ö')
    .replace(/&Ouml;/g, 'Ö')
    .replace(/&rsquo;/g, "'");

  return result;
}

function extractFirstH1(htmlInput: string): [string | null, string] {
  if (typeof htmlInput !== 'string') {
    return [null, htmlInput];
  }

  const h1TagPattern = /<h1[^>]*>([\s\S]*?)<\/h1>/i;
  const firstH1ContentMatch = htmlInput.match(h1TagPattern);

  // Strip HTML from H1 content if a match is found
  const firstH1Content = firstH1ContentMatch
    ? stripHtml(firstH1ContentMatch[1])
    : null;

  // Remove first H1 tag from the original HTML
  const htmlWithoutFirstH1 = firstH1ContentMatch
    ? htmlInput.replace(h1TagPattern, '')
    : htmlInput;

  // First element is the content of the first H1 tag (or null), and the second element is the input HTML string with the first H1 tag removed.
  return [firstH1Content, htmlWithoutFirstH1];
}

export {
  capitalize,
  isCharLetter,
  sluggify,
  safeJsonParse,
  safeJsonStringify,
  addTrailingSlash,
  removeLocale,
  removeTrailingSlash,
  removeSlashes,
  safeRegexExp,
  removeWhitespace,
  stripHtml,
  extractFirstH1,
};
