import gql from 'graphql-tag';
import { defineStore } from 'pinia';
import { arrayIncludesAnyElementInOtherArray } from '#root/shared/utils/types/object-utils';
import { ProductStorageClimate } from '#root/shared/types/graphql-types';
import { cartFragment } from '@/graphql/fragments';
import { addDays, compareAsc, formatISO } from 'date-fns';
import { DISCOUNT_TYPES } from '#root/shared/config/discount-config';
import { CATEGORY_AND_BRAND_COMBINATION_PURCHASE_LIMIT } from '@brand/config/cart-config';
import {
  roomTempCodes,
  chilledTempCodes,
  invalidTempCodes,
} from '../utils/prescription-utils';
// Currently there is a import cycle between cart and prescription store so this eslint ignore is needed
// TODO: Refactor to remove import cycle
// eslint-disable-next-line import/no-cycle
import { usePrescriptionStore } from './prescription';
import { useSubscriptionStore } from './subscription';

const getCartItemLimit = (locale, cartItem) => {
  const { isProd } = useRuntimeConfig().public;
  const env = isProd ? 'prod' : 'dev';

  return (
    CATEGORY_AND_BRAND_COMBINATION_PURCHASE_LIMIT?.[locale]?.[env]?.find(
      (combo) => {
        const hasBrand = combo.brandIds.includes(
          cartItem.meta?.brand?.entityId
        );
        const hasCategory = arrayIncludesAnyElementInOtherArray(
          cartItem.meta.categoryIds,
          combo.categoryIds
        );

        return hasBrand && hasCategory && cartItem.item.quantity > combo.limit;
      }
    )?.limit || 0
  );
};

// Without enabling the persistent carts in BigCommerce,
// the carts are reported to last about a week => setting a conservative expiry time
const isCartExpired = (cartModificationDate) =>
  cartModificationDate
    ? compareAsc(addDays(cartModificationDate, 5), Date.now()) <= 0
    : true;

const rawItemToCartItemObject = (it) => ({
  item: {
    quantity: it.quantity,
    productId: it.productId,
    variantId: it.variantId,
  },
});

const state = () => ({
  cart: null,
  cartRecommendations: null,
  isUpdatingEcom2Basket: false,
  promo: null,
  optimisticItemsCountVariation: 0,
  isUpdatingCart: false,
  shouldSendBrazeAddToCartEventForProductId: null,
});

const getters = {
  bcItemCount: (state) => state?.cart?.itemCount || 0,
  cartAmount: (state) =>
    (state.cart?.amount?.value || 0) +
    (usePrescriptionStore().basketAmount || 0) +
    (useEnabledFeaturesStore().isFeatureEnabled('subscriptions')
      ? useSubscriptionStore().subscriptionAmount
      : 0),
  cartBaseAmount: (state) => state.cart?.baseAmount?.value || 0,
  cartCampaignDiscountAmount: (state) =>
    state.cart?.campaignDiscountAmount?.value || 0,
  cartContainsChilledTempItems: () =>
    usePrescriptionStore().basketContainsChilledTempItems,
  cartContainsFrozenFood: (state) =>
    state.cart?.storageClimates?.includes(ProductStorageClimate.Frozen),
  cartFrozenItemsHandlingFee: (state) =>
    state.cart?.storageClimates?.includes(ProductStorageClimate.Frozen)
      ? state.cart?.frozenItemsHandlingFee?.value || 0
      : 0,
  cartContainsRoomTempItems: () =>
    usePrescriptionStore().basketContainsRoomTempItems,
  cartContainsPrescriptionItem: (state) => state.cart?.containsPrescriptionItem,
  cartContainsOtcItem: (state) => state.cart?.containsOtcItem,
  cartPromo: (state) => state.cart?.promo || null,
  cartPromoDiscountAmount: (state) =>
    state.cart?.promoDiscountAmount?.value || 0,
  cartWithSubscriptions: (state) => {
    if (!useEnabledFeaturesStore().isFeatureEnabled('subscriptions')) {
      return state.cart;
    }
    return {
      ...(state.cart || {}),
      items: {
        ...(state.cart?.items || []),
        ...useSubscriptionStore().subscriptionProducts,
      },
    };
  },
  getChilledTempRxItems: () => {
    const chilledTempRxItems = usePrescriptionStore()?.basketItems?.filter(
      (item) => chilledTempCodes.includes(item.storageTempCode)
    );
    return chilledTempRxItems || [];
  },
  getInvalidTempRxItems: () => {
    const invalidTempRxItems = usePrescriptionStore()?.basketItems?.filter(
      (item) => invalidTempCodes.includes(item.storageTempCode)
    );
    return invalidTempRxItems || [];
  },
  getItemInCart: (state) => (productId, variantId) => {
    // Refetch cart?
    if (state.cart?.items?.length) {
      return state.cart.items.find(
        (item) => item.productId === productId && item.variantId === variantId
      );
    }
    return null;
  },
  getRoomTempRxItems: () => {
    // For some reason i cant get rootGetters to work here so i have to use rootState
    const roomTempItems = usePrescriptionStore()?.basketItems?.filter((item) =>
      roomTempCodes.includes(item.storageTempCode)
    );
    return roomTempItems || [];
  },
  itemCount: (state) =>
    (state?.cart?.itemCount || 0) +
    (usePrescriptionStore().basketItemCount || 0) +
    (useSubscriptionStore().subscriptionItemCount || 0),

  getOptimisticItemCount: (state) =>
    state.isUpdatingCart
      ? state.optimisticItemsCountVariation + state.itemCount
      : state.itemCount,
  lineItems: (state) => state.cart?.items || [],
  getIsUpdatingEcom2Basket: (state) => state.isUpdatingEcom2Basket,
  cartPromoExcludedBrandNames: (state) => {
    // Return the brand names for those cart items that are being excluded from the promo code
    const promoExcludedBrandIds = state.cart?.promo?.excludedBrands || [];

    if (!promoExcludedBrandIds?.length) {
      return [];
    }

    const isPerTotalDiscount =
      state.cart?.promo?.type === DISCOUNT_TYPES.per_total;

    const excludedBrands = state.cart.items
      ?.filter(
        (item) =>
          item.brand && promoExcludedBrandIds.includes(item.brand.entityId)
      )
      ?.map((item) => item.brand.name);

    const allItemsAreByExcludedBrands =
      state.cart.items?.length === excludedBrands.length;

    // If promo code is applied on item level and we have at least one item by an excluded brand,
    // OR if promo code is per total discount i.e. not applied on item level, and all items are by excluded brands,
    // return brand names to show message about promo not being applied for those brands

    if (
      excludedBrands?.length &&
      (!isPerTotalDiscount || allItemsAreByExcludedBrands)
    ) {
      return [...new Set(excludedBrands)];
    }

    return [];
  },
};

export const actions = {
  async addToCart({ cartItems }) {
    const { $i18n, $toast } = useNuxtApp();

    const cartId = localStorage.getItem('cartId');
    const cartModificationDate = this.getCartModificationDate();

    if (!cartId || isCartExpired(cartModificationDate)) {
      if (cartItems && cartItems.length > 0) {
        // Coercing the cart's item into this array of object is ok if it is to be fed to createCart
        // the "cartItems" have an additional meta property that is discarded by createCart
        // Apply caution if you plan to duplicate that behavior elsewhere
        const oldCartItems =
          this.cart?.items.map(rawItemToCartItemObject) || [];

        return this.createCart([...oldCartItems, ...cartItems]);
      }

      return this.createCart(this.cart?.items || []);
    }

    let cartItemLimit = 0;

    if (
      cartItems.some((cartItem) => {
        const currentQuantity =
          this.getItemInCart(cartItem.item.productId, cartItem.item.variantId)
            ?.quantity || 0;

        cartItemLimit = getCartItemLimit($i18n.locale.value, {
          ...cartItem,
          item: {
            ...cartItem.item,
            quantity: cartItem.item.quantity + currentQuantity,
          },
        });

        return cartItem.meta.isOnSale && cartItemLimit;
      })
    ) {
      $toast.error(
        $i18n.t('cart.limit_per_customer', { limit: cartItemLimit })
      );

      this.updateCartModificationDate();

      return this.getCart();
    }

    const { promo } = this;

    try {
      const { $apollo } = useNuxtApp();

      const { data } = await $apollo().mutate({
        mutation: gql`
          mutation AddToCart(
            $id: ID!
            $cartItems: [CartItemInput!]
            $promoCode: String
          ) {
            addCartItems(
              id: $id
              cartItems: $cartItems
              promoCode: $promoCode
            ) {
              ...CartFields
            }
          }
          ${cartFragment}
        `,
        variables: {
          cartItems: cartItems.map((cartItem) => cartItem.item),
          id: cartId,
          promoCode: promo?.code,
        },
      });
      const { addCartItems } = data;
      this.setCart(addCartItems);
      return addCartItems;
    } catch (error) {
      return this.handleCartError({ cartItems, error });
    }
  },

  async createEcom2Basket({ basketId }) {
    let cartId = localStorage.getItem('cartId');
    const cartModificationDate = this.getCartModificationDate();

    if (!cartId || isCartExpired(cartModificationDate)) {
      const cart = await this.createCart([]);
      cartId = cart.id;
    }
    if (this.cart && !this.cartContainsPrescriptionItem) {
      try {
        this.setIsUpdatingEcom2Basket(true);

        const { $apollo } = useNuxtApp();
        const { getBasket: getPrescriptionBasket } = usePrescriptionStore();

        const { promo } = this;
        const { data } = await $apollo().mutate({
          mutation: gql`
            mutation CreateEcom2Basket(
              $id: ID!
              $basketId: String
              $promoCode: String
            ) {
              createEcom2Basket(
                id: $id
                basketId: $basketId
                promoCode: $promoCode
              ) {
                ...CartFields
              }
            }
            ${cartFragment}
          `,
          variables: {
            basketId:
              basketId === '0'
                ? '100' /* TODO: remove this h4x for demo */
                : basketId,
            id: cartId,
            promoCode: promo?.code,
          },
        });
        const { createEcom2Basket } = data;
        this.setCart(createEcom2Basket);
        getPrescriptionBasket();
        this.setIsUpdatingEcom2Basket(false);
        return createEcom2Basket;
      } catch (error) {
        this.setIsUpdatingEcom2Basket(false);
        useNuxtApp().$sentryCaptureException(
          new Error(
            `🚨🚨🚨 ECOM2 BASKET WITH CART ID ${cartId} COULD NOT BE CREATED: ${error.message}`
          )
        );
        return error;
      }
    }
    return null;
  },

  async createCart(cartItems) {
    const { $apollo } = useNuxtApp();

    const { promo } = this;

    try {
      const { data } = await $apollo().mutate({
        mutation: gql`
          mutation CreateCart(
            $cartItems: [CartItemInput!]
            $promoCode: String
          ) {
            createCart(cartItems: $cartItems, promoCode: $promoCode) {
              ...CartFields
            }
          }
          ${cartFragment}
        `,
        variables: {
          cartItems: cartItems.map((cartItem) => cartItem.item),
          promoCode: promo?.code,
        },
      });

      const { createCart } = data;
      this.updateCartModificationDate();
      this.setCart(createCart);

      // A new cart is created and it should send the Braze add to cart event
      this.setShouldSendBrazeAddToCartEventForProductId(
        cartItems[0]?.item?.productId
      );

      return createCart;
    } catch (error) {
      return this.handleCartError({ error });
    }
  },

  async deleteEcom2Basket() {
    if (this.cartContainsPrescriptionItem) {
      this.setIsUpdatingEcom2Basket(true);
      const cartId = localStorage.getItem('cartId');
      const { promo } = this;
      try {
        const { $apollo } = useNuxtApp();

        const { data } = await $apollo().mutate({
          mutation: gql`
            mutation DeleteEcom2Basket($id: ID!, $promoCode: String) {
              deleteEcom2Basket(id: $id, promoCode: $promoCode) {
                ...CartFields
              }
            }
            ${cartFragment}
          `,
          variables: {
            id: cartId,
            promoCode: promo?.code,
          },
        });
        const { deleteEcom2Basket } = data;
        this.setCart(deleteEcom2Basket);
        this.setIsUpdatingEcom2Basket(false);
        return deleteEcom2Basket;
      } catch (error) {
        this.setIsUpdatingEcom2Basket(false);
        useNuxtApp().$sentryCaptureException(
          new Error(
            `🚨🚨🚨 ECOM2 BASKET WITH CART ID ${cartId} COULD NOT BE REMOVED: ${error.message}`
          )
        );
        return error;
      }
    }
    return null;
  },

  async deleteCartItem(cartItemId) {
    const cartId = localStorage.getItem('cartId');
    const cartModificationDate = this.getCartModificationDate();

    if (isCartExpired(cartModificationDate)) {
      const oldCartItems =
        this.cart?.items
          .filter((it) => it.id !== cartItemId)
          .map(rawItemToCartItemObject) || [];

      if (!oldCartItems?.length) {
        this.setCart(null);
        return null;
      }

      return this.createCart(oldCartItems);
    }

    if (cartId) {
      const { promo } = this;

      try {
        const { $apollo } = useNuxtApp();

        const { data } = await $apollo().mutate({
          mutation: gql`
            mutation DeleteCartItem(
              $id: ID!
              $cartItemId: ID!
              $promoCode: String
            ) {
              deleteCartItem(
                id: $id
                cartItemId: $cartItemId
                promoCode: $promoCode
              ) {
                ...CartFields
              }
            }
            ${cartFragment}
          `,
          variables: {
            cartItemId,
            id: cartId,
            promoCode: promo?.code,
          },
        });
        const { deleteCartItem } = data;
        this.setCart(deleteCartItem);
        return deleteCartItem;
      } catch (error) {
        return this.handleCartError({ error });
      }
    }
    return null;
  },

  async getCart() {
    useSubscriptionStore().loadSubscriptionProducts();

    let cartId = localStorage.getItem('cartId');

    // Create cart if no cart and subscription products exist
    if (!cartId) {
      const { subscriptionProducts } = useSubscriptionStore();
      if (subscriptionProducts.length > 0) {
        const cart = await this.createCart([]);
        cartId = cart.id;
      }
    }

    const cartModificationDate = this.getCartModificationDate();

    if (isCartExpired(cartModificationDate)) {
      const oldCartItems = this.cart?.items.map(rawItemToCartItemObject) || [];

      if (!oldCartItems?.length) {
        this.setCart(null);
        return null;
      }

      return this.createCart(oldCartItems);
    }

    if (cartId) {
      const { promo } = this;

      try {
        const { $apollo } = useNuxtApp();
        const prescriptionStore = usePrescriptionStore();
        const { isLoggedInToEcom2 } = storeToRefs(prescriptionStore);

        const { data } = await $apollo().query({
          fetchPolicy: 'no-cache',
          query: gql`
            query Cart($id: ID!, $promoCode: String) {
              cart(id: $id, promoCode: $promoCode) {
                ...CartFields
              }
            }
            ${cartFragment}
          `,
          variables: {
            id: cartId,
            promoCode: promo?.code,
          },
        });

        const { cart } = data;
        this.setCart(cart);

        if (this.cartContainsPrescriptionItem && isLoggedInToEcom2.value) {
          prescriptionStore.getBasket();
        }
        return cart;
      } catch (error) {
        return this.handleCartError({ error });
      }
    }
    return null;
  },

  async getCartRecommendations() {
    if (this.cart) {
      try {
        const cartRecommendations = await useNuxtApp().$apiFetch(
          '/rest/search/cart-recommendations',
          {
            method: 'POST',
            body: {
              ids: this.cart.items.map((item) => item.productId),
              take: 3,
            },
            headers: { 'User-Id': useLoop54UserIdCookie().value },
          }
        );

        this.setCartRecommendations(cartRecommendations);
        return cartRecommendations;
      } catch (error) {
        console.error(
          'Error fetching cart recommendations',
          error,
          error?.response
        );
        this.setCartRecommendations(null);
      }
    }
    return null;
  },

  handleCartError({ error, cartItems }) {
    const { clearBasket: clearPrescriptionBasket } = usePrescriptionStore();
    const is404 =
      error?.graphQLErrors?.[0]?.extensions?.response?.status === 404;

    if (is404) {
      this.clearCart();
      clearPrescriptionBasket();

      if (cartItems) {
        return this.createCart(cartItems);
      }
    }

    return error;
  },

  async updateCartItem({ itemId, cartItem }) {
    const { $i18n, $toast } = useNuxtApp();

    this.isUpdatingCart = true;

    const cartId = localStorage.getItem('cartId');
    const cartModificationDate = this.getCartModificationDate();

    if (isCartExpired(cartModificationDate)) {
      const currentcartItems = cartItem ? [cartItem] : [];

      const oldCartItems =
        this.cart?.items
          .filter((it) => it.id !== itemId)
          .map(rawItemToCartItemObject) || [];

      if (!oldCartItems?.length && !currentcartItems?.length) {
        this.setCart(null);
        return null;
      }

      return this.createCart([...oldCartItems, ...currentcartItems]);
    }

    if (cartId) {
      const { promo } = this;

      const cartItemLimit = getCartItemLimit($i18n.locale.value, cartItem);

      if (cartItem.meta.isOnSale && cartItemLimit) {
        $toast.error(
          $i18n.t('cart.limit_per_customer', { limit: cartItemLimit })
        );

        return this.getCart();
      }

      try {
        const { $apollo } = useNuxtApp();

        // Calculate quantity variation and update optimistically
        const quantityDifference =
          cartItem.item.quantity -
          (this.cart.items.find((i) => i.id === itemId)?.quantity || 0);
        this.optimisticItemsCountVariation = quantityDifference;

        const { data } = await $apollo().mutate({
          mutation: gql`
            mutation UpdateCartItem(
              $id: ID!
              $itemId: ID!
              $cartItem: CartItemInput!
              $promoCode: String
            ) {
              updateCartItem(
                id: $id
                cartItemId: $itemId
                cartItem: $cartItem
                promoCode: $promoCode
              ) {
                ...CartFields
              }
            }
            ${cartFragment}
          `,
          variables: {
            cartItem: cartItem.item,
            id: cartId,
            itemId,
            promoCode: promo?.code,
          },
        });
        const { updateCartItem } = data;
        this.setCart(updateCartItem);
        this.isUpdatingCart = false;
        return updateCartItem;
      } catch (error) {
        this.isUpdatingCart = false;
        this.handleCartError({ error });
      }
    }
    return null;
  },

  // Update storage climates with climates from rx orders
  addStorageClimatesWithRxOrder(payload) {
    if (!this.cart.storageClimates.includes(payload)) {
      this.cart.storageClimates.push(payload);
    }
  },

  clearCart() {
    localStorage.removeItem('cartId');
    localStorage.removeItem('cartModificationDate');
    this.cart = null;
    this.cartRecommendations = null;
  },

  removeCartRecommendation(cartRecommendationId) {
    this.cartRecommendations = this.cartRecommendations.filter(
      (cartRecommendation) => cartRecommendation.id !== cartRecommendationId
    );
  },

  removeStorageClimatesWithRxOrder(payload) {
    if (!this.cart) return;

    this.cart.storageClimates = this.cart.storageClimates.filter(
      (item) => item !== payload
    );
  },

  getCartModificationDate() {
    const rawValue = localStorage.getItem('cartModificationDate');

    if (!rawValue) return rawValue;

    return new Date(rawValue);
  },

  updateCartModificationDate(value = new Date()) {
    localStorage.setItem('cartModificationDate', formatISO(value));
  },

  setCart(cart) {
    const currentCartModificationDate = this.getCartModificationDate();

    if (cart && !isCartExpired(currentCartModificationDate)) {
      localStorage.setItem('cartId', cart.id);
      this.updateCartModificationDate();
    } else {
      localStorage.removeItem('cartId');
      localStorage.removeItem('cartModificationDate');
    }

    this.cart = cart;
    this.promo = cart?.promo || null;
  },

  setCartRecommendations(cartRecommendations) {
    this.cartRecommendations = cartRecommendations;
  },
  setIsUpdatingEcom2Basket(payload) {
    this.isUpdatingEcom2Basket = payload;
  },
  setPromoCode(promoCode) {
    this.promo = { code: promoCode };
  },
  setShouldSendBrazeAddToCartEventForProductId(payload) {
    this.shouldSendBrazeAddToCartEventForProductId = payload;
  },
};

export const useCartStore = defineStore('cart', {
  state,
  getters,
  actions,
});
